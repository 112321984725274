<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col class="text-right">
      <app-btn @click="dialog = true">
        <v-icon>mdi-account-plus-outline</v-icon> Add New Fare
      </app-btn>
    </v-col>
    <material-card
      icon="mdi-account-group"
      icon-small
      color="primary"
      title="Fares"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="fares"
          :search.sync="search"
          :footer-props="{
            showFirstLastPage: true,
          }"
        >
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="650px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="FareForm">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.tarifName"
                            label="Name"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.flagFall"
                            label="Flag Fall"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.distanceRate"
                            label="Distance Rate"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.timeRate"
                            label="Waiting Time"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <label>Start time</label>
                          <v-time-picker
                            format="ampm"
                            v-model="editedItem.startTime"
                          ></v-time-picker>
                        </v-col>
                        <v-col cols="12" md="6">
                          <label>End time</label>
                          <v-time-picker
                            format="ampm"
                            v-model="editedItem.endTime"
                          ></v-time-picker>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.flagFall`]="{ item }">
            {{ currencyFormat(item.flagFall ? item.flagFall / 100 : "$0.00") }}
          </template>
          <template v-slot:[`item.distanceRate`]="{ item }">
            {{
              currencyFormat(
                item.distanceRate ? item.distanceRate / 100 : "$0.00"
              )
            }}/km
          </template>
          <template v-slot:[`item.timeRate`]="{ item }">
            {{
              currencyFormat(item.timeRate ? item.timeRate / 100 : "$0.00")
            }}/minute
          </template>
          <template v-slot:[`item.startTime`]="{ item }">
            {{ timeFormat(item.startTime) + " - " + timeFormat(item.endTime) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import moment from "moment";
export default {
  name: "DataTablesView",
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "tarifName",
      },
      { text: "Flag Fall", value: "flagFall" },
      { text: "Distance Rate", value: "distanceRate" },
      { text: "Waiting Time", value: "timeRate" },
      { text: "Time Range", value: "startTime" },
      { text: "Actions", align: "end", value: "actions", sortable: false },
    ],
    statuses: [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ],
    fares: [],
    editedIndex: -1,
    editedItem: {
      tarifName: "",
      flagFall: "",
      distanceRate: "",
      timeRate: "",
      startTime: "",
      endTime: "",
      distanceUnit: "km",
      active: true,
    },
    defaultItem: {
      tarifName: "",
      flagFall: "",
      distanceRate: "",
      timeRate: "",
      startTime: "",
      endTime: "",
      distanceUnit: "km",
      active: true,
    },
    search: undefined,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Fare" : "Edit Fare";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$http
        .get("/Tarifs")
        .then((response) => {
          let result = response.data.data;
          if (result) {
            this.fares = result.sort((a, b) => b.tarifId - a.tarifId);
          }
        })
        .catch(() => {});
    },

    editItem(item) {
      this.editedIndex = this.fares.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.flagFall /= 100;
      this.editedItem.distanceRate /= 100;
      this.editedItem.timeRate /= 100;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.fares.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let index = this.editedIndex;
      this.$http
        .delete(`/Tarifs/${this.editedItem.tarifId}`)
        .then((response) => {
          if (response.data.success) {
            this.fares.splice(index, 1);
            this.$toast.success("Fare deleted successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error("Something went wrong.", "Error", {
              position: "topRight",
            });
          }
        })
        .catch(() => {});
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      let index = this.editedIndex;
      this.editedItem.flagFall *= 100;
      this.editedItem.distanceRate *= 100;
      this.editedItem.timeRate *= 100;
      if (this.$refs.FareForm.validate()) {
        if (index > -1) {
          this.$http
            .put(`/Tarifs/${this.editedItem.tarifId}`, this.editedItem)
            .then((response) => {
              if (response.data.success) {
                this.$toast.success(response.data.message, "Success", {
                  position: "topRight",
                });
                this.fares.splice(index, 1, response.data.data);
              } else {
                this.$toast.error(response.data.message, "Error", {
                  position: "topRight",
                });
              }
            })
            .catch(() => {});
        } else {
          this.$http
            .post("/Tarifs", this.editedItem)
            .then((response) => {
              if (response.data.success) {
                this.fares.unshift(response.data.data);
                this.$toast.success(response.data.message, "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error(response.data.message, "Error", {
                  position: "topRight",
                });
              }
            })
            .catch(() => {});
        }
        this.close();
      }
    },
    currencyFormat(val) {
      return "$" + parseFloat(val).toFixed(2);
    },
    timeFormat(val) {
      return moment("0001-01-01T" + val).format("h:mm a");
    },
  },
};
</script>
